@import '../../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  margin-top: 0;
  font-size: 38px;
  color: #025196;
  font-weight: 600;
  font-family: 'Josefin Sans';
  text-align: center;
  margin-bottom: 10px;
  @media (max-width: 575px) {
    font-size: 26px;
    text-align:left;
  }
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

}

.step {
  @media (--viewportMedium) {
    /* width: 324px; */
    margin-right: 20px;
  }
  & > p{
    max-width: 870px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-family: 'Josefin Sans';
  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
.BusinessPotentialWrap{
  margin-top:30px;
}