@import '../../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  }
}

.heroContent {
  margin: auto 24px 70px 60px;

  @media (max-width: 1199px) {
    max-width: 40%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 20px auto;
    text-align: center;
  }

  /* @media (--viewportMedium) {
    margin: 0px 14.5vw 0 -2.5vw;
  } */

  /* Special viewport for adjusting the heroContent's placement */

  /* @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  } */

  @media only screen and (min-width: 1367px) {
    /* margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px; */
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  composes: animation;
  font-size: 42px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Josefin Sans';
  line-height: 50px;
  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
  @media(max-width:767px){
    font-size: 35px;
  }
  @media(max-width:575px){
    font-size: 30px;
    line-height:40px;
  }
}
.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h4 from global;
  font-family: 'Avenir';
  color: #fffffd;
  margin: 14px 0 45px 0 !important;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 1px;

  composes: animation;



  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
  @media(max-width:1200px){
    max-width: 540px !important;
  }
  @media (max-width: 991px) {
    margin-bottom: 20px !important;
  }
  @media(max-width:767px){
    max-width: none !important;
    padding: 0 15px;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
.heroButtonFEDelay {
  animation-delay: 0.3s;
}
.mainNumberDetails {
  display: flex;
  max-width: 45%;
  @media (max-width: 991px) {
    max-width: 100%;
    justify-content: center;
    margin: 0 0 20px;
  }
  & div:nth-child(2) {
    margin-left: 72px;
  }
}
.numberDetails {
  display: flex;
  flex-direction: column;
  color: #dbe8f9;
  & span:nth-child(2),
  & span:nth-child(3) {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
  }
}
.number {
  font-size: 28px;
  font-weight: 600;
  font-family: 'Josefin Sans';
}
.contentandimage {
  position: absolute;
  right: 100px;
  @media (max-height: 1199px) {
    top: 60px;
  }
  @media (max-width: 991px) {
    position: inherit;
  }
  @media (max-width: 575px) {
    text-align: center;
    padding: 0 15px;
  }
  & img {
    width: auto;
    height: 555px;
    object-fit: cover;
    border: 10px solid #4d85b5;
    border-radius: 216px 216px 0px 0px;
    box-shadow: 0px 2px 20px #dbe8f999;
    @media (max-width: 575px) {
      width: 100%;
      height: auto;
    }
  }
}
