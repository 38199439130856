@import '../../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  font-family: 'Avenir';
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
  color: #025196;
  line-height: 24px;
  @media (max-width: 767px) {
    font-size: 1.2rem;
  }
}
.subtitle {
  font-size: 30px;
  color: #292929;
  font-weight: 500;
  font-family: 'Josefin Sans';
  @media (max-width: 767px) {
    font-size: 1.5rem;
    max-width: 80%;
    margin-bottom: 20px;
  }
}
.filteredSearches {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 0px;
  }
  @media (max-width: 767px) {
    & > div {
      & > div:nth-child(2) {
        & > div {
          /* transform: translate3d(0px, 0px, 0px) !important; */
          & > div {
            min-width: 320px;
          }
        }
      }
    }
  }
}

.searchLink {
  background: #fff;
  padding: 15px 15px 30px;
  border-radius: 10px;
  margin: 15px 5px;
  box-shadow: 0 0 26px -6px #cecece;
  border: 1px solid transparent;
  transition: ease-in-out;

  &:hover {
    border: 1px solid #242d49;
    /* transform: scale(1.02); */
    /* box-shadow: var(--boxShadowSectionLocationHover); */
  }
}

/* @media (--viewportMedium) {
  .searchLink {
    margin-top: 0;
    margin-right: 40px;
    margin-bottom: 0;
    max-width: 257px;
    max-height: 430px;

    &:last-of-type {
      margin-right: 0;
    }
  } 
}*/

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 10px;
  transition: var(--transitionStyleButton);
  /* max-height: 430px; */
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  position: relative;
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */
  @media (--viewportMedium) {
    /* padding-bottom: calc(3 / 2 * 100%); 3:2 Aspect Ratio */
    padding-bottom: 220px;
  }
  @media (max-width: 767px) {
    padding-bottom: 0;
  }
}

.searchImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 220px;
  border-radius: 10px;
  object-fit: cover;
  margin: 0 0 15px;

  @media (max-width: 767px) {
    position: inherit;
  }
}

.linkText {
  margin-bottom: 0;
  margin-top: 15px;
  /* min-height:70px; */
  /* @media(max-width:991px){
    min-height: 40px;
  } */
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.searchName {
  text-transform: capitalize;
  font-family: var(--fontFamilyJosefin);
  font-size: 18px;
  font-weight: 600;
  color: #292929;
  line-height: 24px;
  max-width: 500px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.countryNames {
  margin-top: 0;
  font-family: var(--fontFamilyJosefin);
  color: #292929;
  line-height: 22px;
  font-size: 15px;
  font-weight: 400;
}
.CountryText {
  margin-top: 0;
  font-family: var(--fontFamilyJosefin);
  color: #292929;
  line-height: 22px;
  font-size: 15px;
  font-weight: 400;
  min-height: 45px;
}
.price {
  font-size: 18px;
  font-weight: 800;
  color: #000;
  margin: 0 0 10px 0;
  display: block;
}
.priceDetail {
  margin-top: 10px;
}
.buttonDetail {
  background-color: #023462;
  color: #fffffd;
  width: 100%;
  font-size: 14px;
  border-radius: 8px;
  margin: 0;
  border: 0;
  padding: 5px 15px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  &:hover {
    text-decoration: none;
    background-color: #000;
  }
  /* & span{
    width: 100%;
  } */
}
.search {
  display: flex;
  flex-direction: row;
}
.discoverMore {
  color: #025196;
  display: table;
  font-weight: 800;
  margin: 40px auto 10px;
  font-size: 18px;
  & span {
    border-bottom: 2px solid #025196;
  }
  &:hover {
    text-decoration: none;
  }
  /* text-decoration: underline; */
  @media (max-width: 767px) {
    margin: 40px 0 10px;
  }
}
.saveButton {
  position: absolute;
  right: 0;
  display: flex;
  border-radius: 0 10px 0 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fffffd;
  z-index: 9;
  border: 0;
  align-items: center;
  padding: 2px 15px;
  cursor: pointer;
}
.saveButton svg {
  margin: 0 8px 0 0;
  fill: #fdb338;
}
.tagspan {
  position: absolute;
  z-index: 9;
  bottom: 0;
  right: 0;
  background: #a2a2a2;
  border-bottom-right-radius: 10px;
  padding: 0px 10px;
  font-size: 13px;
  line-height: 28px;
  display: flex !important;
  align-items: center;
  font-weight: 400 !important;
  color: #fff !important;
}
.tagspan svg {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  background: #fff;
  border-radius: 50%;
  fill: #4db595;
}
.rtedv {
  /* margin-left: auto; */
  margin-top: -5px;
  margin-bottom: 10px;
  & > svg {
    height: 12px;
    width: 15px;
  }
}
